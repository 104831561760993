// TODO: Delete this in sprint 2

export const FooterMock = {
  name: "ติดต่อเรา",
  address: "ติดต่อเรา",
  facebookUrl: "https://th-th.facebook.com/fecampchula/",
  instagramUrl: "https://www.instagram.com/fecampcu/",
  youtubeUrl: "https://www.youtube.com/channel/UCzAKRq31Xm-lqcP4ZkI5ZSg",
  copyright: "Fundamental Engineering Stimulation Test 15 | 2022",
}
